/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue: #316ce8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff0000 !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #36b9cc !default;
$cyan: #17a2b8 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
  Custom Colors
----------------------------------------------------------------------------- */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;

/* ----------------------------------------------------------------------------
  Theme Colors
----------------------------------------------------------------------------- */
$theme-primary: #0066cc !default;
$theme-secondary: #e94c48 !default;
$theme-nav-primary: $theme-primary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color: #1072be !default;

/* ----------------------------------------------------------------------------
  CTA buttons
----------------------------------------------------------------------------- */
$cta-red: #ff0033 !default;
$cta-orange: #ff6633 !default;
$cta-green: #5cb865 !default;
$cta-blue: #0099ff !default;
$cta-yellow: #ffc107 !default;
$cta-dark: #555555 !default;

/* ----------------------------------------------------------------------------
  Text colors
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-secondary {
  color: $theme-secondary !important;
}
.text-dark {
  color: $cta-dark !important;
}

/* ----------------------------------------------------------------------------
  Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: $theme-primary !important;
}
.bg-theme-secondary {
  background-color: $theme-secondary !important;
}
