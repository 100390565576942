/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #0a0101;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding: 20px 0 15px;
  width: 100%;
  background-color: #fff;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 10px 0;
  }
  a {
    color: #555;
    &:hover {
      color: darken(#555, 10%);
    }
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  a.logo {
    float: left;
    margin: 15px 0;
    border: 0;
    width: 80%;
    max-width: 480px;
    img {
      float: left;
      margin-right: 15px;
      width: auto;
      height: auto;
      max-width: 80%;
    }
    span.big,
    span.small {
      font-size: 1.5em;
      color: #555;
      line-height: 1em;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      font-size: 16px;
      font-family: var(--font-family);
    }
  }
  #support {
    margin: 0;
    width: auto;
    p {
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      padding: 0 12px 2px 12px;
      color: #fff;
      background: #3f3f3f;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten(#3f3f3f, 7.5%);
      }
    }
    span.phone {
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      color: #555;
      margin-left: 5px;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  .dropdown-list .drop {
    max-width: 350px;
  }
  .dropdown-list span.is-active + .drop {
    max-width: 350px;
  }
}
#header-wrap {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}
/* ----------------------------------------------------------------------------
    Start Page Styles
----------------------------------------------------------------------------- */
#banner-wrap {
  background: #93dbf4 url(../../images/banner-bg-sm.jpg) no-repeat 0 50% / cover;
  border-bottom: 1px solid #ccc;
  &.state {
    background: #fff url(../../images/banner-wrap-clouds.jpg) repeat-x center top;
    background-size: cover;
    h1 {
      font: inherit;
      font-weight: 600;
      font-size: 2em;
      line-height: 1.2;
      span {
        font-size: 0.7em;
        line-height: 1.4em;
        color: #555;
      }
    }
  }
  .card {
    background: rgba(255, 255, 255, 0.8);
  }
}
.action {
  .price-bg-from {
    background: url(../images/price-bg-from.png) no-repeat center center;
  }
  .price-bg-course-fee {
    background: url(../images/price-bg-course-fee.png) no-repeat center center;
  }
}

.action {
  .price {
    z-index: 7;
  }
}
a.register-link {
  color: #bc2120 !important;
}
.bright-red {
  color: #ff0033;
}
ol.points {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    img {
      float: left;
      width: 40px;
      height: auto;
      margin-top: 5px;
      margin-right: 10px;
    }
    p {
      overflow: hidden;
      margin-left: 50px;
    }
  }
}
.section {
  background: #fff url(../images/section-bg.jpg) no-repeat center top;
  background-size: cover;
  border: 1px solid #c5e2f0;
  h3 {
    color: #000;
    a {
      color: $theme-secondary;
    }
  }
  .icon-facebook {
    background: #3f5495;
    &:hover {
      background: darken(#3f5495, 10%);
    }
  }
  .icon-linkedin {
    background: #2575b2;
    &:hover {
      background: darken(#2575b2, 10%);
    }
  }
}

#aside {
  #video {
    border: 1px solid #c30;
    span {
      color: $theme-primary;
      font-size: 1.5em;
      font-family: 'Titillium Web', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    }
    &:hover {
      border: 1px solid #06c;
    }
  }
  #flow {
    background: #edfaff;
    border: 1px solid $theme-primary;
    > div:hover {
      i {
        transition: 0.2s;
        color: $theme-primary;
      }
    }
  }
  blockquote {
    line-height: 1.4em;
    & p {
      color: #555;
    }
    & p:before,
    & p:after {
      margin-right: 5px;
      content: '\201C';
      color: #ccc;
      font-family: serif;
      font-size: 26px;
      font-style: normal;
      display: inline-block;
      font-weight: 700;
    }
    & p:after {
      margin-left: 5px;
      content: '\201D';
    }
  }
}
.reg-btn {
  min-width: 210px;
  padding: 0.75rem;
  font:
    800 18px/1.2em 'Titillium Web',
    'Trebuchet MS',
    Verdana,
    Tahoma,
    Helvetica,
    Arial,
    sans-serif;
}
.price {
  background: url(../images/price-bg.png) no-repeat center center;
  padding-top: 57px;
  width: 150px;
  height: 150px;
  font:
    bold 0 'Trebuchet MS',
    'Droid Serif',
    Arial,
    sans-serif;
}
#reviews {
  blockquote {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-size: 1.35em;
    line-height: 1.4em;
    font-weight: 400;
    color: #444;
    border: 0;
  }
  blockquote p {
    margin: 0;
    padding: 0;
    font-family: Georgia, 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-style: italic;
    &:before,
    &:after {
      margin-right: 8px;
      display: inline-block;
      content: '\201C';
      color: #ccc;
      font-family: serif;
      font-size: 36px;
      font-weight: 700;
      vertical-align: middle;
      font-style: normal;
    }
    &:after {
      margin-left: 8px;
      content: '\201D';
    }
  }
  blockquote p a {
    color: #5d5d5d;
    border: 0;
    font-size: 18px;
  }
  p.more-reviews {
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
  }
  .reviewer {
    font-size: 14px;
  }
}
.round-box {
  h3 {
    color: #000;
  }
  background-color: #fdfdfd;
}
a.phone i {
  height: 35px;
  width: 35px;
  font-size: 1.4em;
  color: #ccc;
  line-height: 30px;
  border: 2px solid #ccc;
}
h4 {
  color: $cta-dark;
  font-weight: 500;
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 220px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 16px;
    padding-bottom: 14px;
    color: #fff;
    background: $cta-red;
    background-image: linear-gradient(lighten($cta-red, 8), darken($cta-red, 5));
    border: 0;
    height: auto;
    line-height: 1;
    text-align: center;
    font:
      800 18px / 1.2em 'Titillium Web',
      'Trebuchet MS',
      Verdana,
      Tahoma,
      Helvetica,
      Arial,
      sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 50px;
      width: 220px !important;
      li a {
        color: #333 !important;
      }
    }
    &:after {
      display: none;
    }
    &:hover {
      background: darken($cta-red, 7) !important;
    }
  }
  &.small {
    span.dropdown {
      padding: 13px 0 11px 0;
      font-size: 18px;
      & + .drop {
        top: 42px;
      }
    }
  }
}

.ms-n15 {
  margin-left: -15px;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .action {
    .register-review {
      a.reg-btn {
        top: 21px;
      }
    }
    .reviews {
      margin: 0;
      padding: 15px 0 0 0;
      width: 100px;
      text-align: center;
      background: url(../images/review-star.png) no-repeat 50% 0;
      font:
        14px/20px Verdana,
        Tahoma,
        Helvetica,
        Arial,
        sans-serif;
      color: #ff0033;
      position: relative;
      left: 21px;
      top: 8px;
      z-index: 8;
      border-bottom: 1px solid transparent;
    }
    a.reg-btn {
      margin: 20px 0 20px -30px !important;
      z-index: 1;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // this adds an extra padding to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 1.5rem);
    padding-left: var(--bs-gutter-x, 1.5rem);
  }
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
}
// roughly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header-wrap {
    border-top: 3px solid #3f3f3f;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  // this reverts the extra padding added to fluid containers
  [class*='container-'] {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #banner-wrap {
    background: url(../../images/banner-bg-lg.jpg) no-repeat 0 20% / cover;
    &.florida {
      #banner {
        background: url(../images/florida-banner-bg.png) no-repeat 95% 100%;
      }
    }
    &.nevada {
      #banner {
        background: url(../images/nevada-banner-bg.png) no-repeat 95% 100%;
      }
    }
    &.online {
      #banner {
        background: url(../images/online-banner-bg.png) no-repeat 95% 100%;
      }
    }
    &.virginia {
      #banner {
        background: url(../images/virginia-banner-bg.png) no-repeat 95% 100%;
      }
    }
  }
  .clear-list:nth-child(2) {
    margin-left: 20px;
  }
  #pitch {
    .pitch-icons {
      max-height: 105px;
    }
    .section {
      background: #fff url(../images/section-bg.jpg) no-repeat center top;
      p {
        margin: 10px 0 10px 20px;
        font-size: 21px;
        line-height: 1.3em;
        font-weight: 600;
        font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
      }
      img {
        position: relative;
        left: 0px;
        bottom: 89px;
        float: right;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Extra extra large devices (extra large desktops, 1400px and up)
@media (min-width: 1400px) {
}
